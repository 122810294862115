import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/committees/hero"
import DEI from "../components/committees/dei"
import Quicktabs from "../components/committees/quick-tabs"

const Committees = ({ data }) => (
  <div className="committeesPage">
    <Layout>
      <Seo title="Committees" />
      <div className="main_content_wrapper about_page">
        <Hero data={data.contentfulDei} />
        <Quicktabs data={data.contentfulDei} />
        <DEI data={data.contentfulDei} />
      </div>
    </Layout>
  </div>
)

export default Committees

export const query = graphql`
  query Dei($language: String) {
    contentfulDei(node_locale: { eq: $language }) {
      title
      linksTitle
      links {
        id
        label
        url
      }
      committeeCouncil {
        id
        headline1
        headline2
        url
        info {
          info
        }
        members {
          id
          name
          designation
          additionalPosition
          socialMedia
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
