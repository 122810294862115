import React from "react"

const Hero = ({ data }) => {
  return (
    <div className="common_banner_section">
      <div className="container">
        <div className="banner_center_section">
          <h1>{data.title}</h1>
        </div>
      </div>
    </div>
  )
}

export default Hero
