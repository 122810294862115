import React from "react"

const DEIQuickTabs = ({ data }) => {
  return (
    <div className="mandate_section">
      <div>
        <div className="quick_tab_section_fake"></div>
        <div className="quick_tab_section">
          <div className="container">
            <div className="quick_slidemenu">
              <div className="quick_tab_inner">
                <div className="quick_tab_title">
                  <div className="title_red_line">
                    <span>{data.linksTitle}</span>
                  </div>
                </div>
                <div className="wrapall_tab" style={{ padding: "0px" }}>
                  <div className="all_quick_tabs">
                    {data.links.map(link => (
                      <a href={link.url} key={link.id}>
                        <div className="tab_btn">
                          <span>{link.label}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DEIQuickTabs
