import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

const DEICommittee = ({ data }) => {
  return data.committeeCouncil.map(item => (
    <div className="bod_section" id={item.url} key={item.id}>
      <div className="fp_image">
        <img src="assets/image/About/fingure_print.png" alt="Fingureprint" />
      </div>
      <div className="container">
        <div className="bod_block">
          <div className="common_section_title">
            <h2>
              {item.headline1} <span>{item.headline2}</span>
              {/* <FormattedMessage
                id="dc"
                values={{ span: (...chunks) => <span>{chunks}</span> }}
              /> */}
            </h2>
            <p className="comittees-subtext">
              {item.info.info}
              {/* <FormattedMessage id="deiPara" /> */}
            </p>
          </div>
          <div className="bod_box_block">
            <div className="bod_box_inner">
              {item.members.map(member => (
                <div className="bod_box_col" key={member.id}>
                  <div className="bod_box show_modal">
                    <a href={member.socialMedia} target="_blank">
                      <div className="bod_img_box">
                        <img src={member.image.file?.url} />
                      </div>
                      <div className="bod_box_info">
                        <h5>{member.name}</h5>
                        <p>{member.designation}</p>                        
                        {member.additionalPosition !== "#" && (
                          <p className="officer-title">{member.additionalPosition}</p>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="loadmore_btn">
              <a href="#0" className="load_more">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
}

export default DEICommittee
